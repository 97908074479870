export const portafolio = [
  {
    category: "all",
    path: "/Photorealistic-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/All/0.Exterior-3D-Rendering-Residential-High-End-Pool-House-New-York.webp",
        name: "Aspire Catalog",
        location: "New York, New York",
        usage: "Digital Catalog",
        tags: " Exterior pool, Interior Design, 3D Rendering, 3D Visualization, CGI, Residential",
        link: "/Residential-Rendering-Services",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/All/1.Interior-3D-Rendering-Residential-High-End-Apartment-New-York.webp",
        name: "Micro-units Ollie",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Interior Bedroom, Interior Design, 3D Rendering, 3D Visualization, CGI, Residential",
        link: "/Residential-Rendering-Services",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/All/2.Exterior-3D-Rendering-Brownstone-New-York.webp",
        name: "Brownstones",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Exterior Brownstone, Architectural Design, Restauration, 3D Rendering, 3D Visualization, CGI, Residential",
        link: "/Brownstone-Rendering-Services",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/All/3.Interior-3D-Rendering-Condominium-Apartment-New-York.webp",
        name: "The Bowery",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Interior Condo, Interior Design, 3D Rendering, 3D Visualization, CGI, Condominium",
        link: "/Apartments&Condo-Rendering-Services",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/All/4.Exterior-3D-Rendering-Condominium-Apartment-New-York.webp",
        name: "790 Madison Ave",
        location: "New York, New York",
        usage: "Press Release | Presentation | Planning",
        tags: "Exterior Facade, Urbanization, 3D Rendering, 3D Visualization, CGI, Condominium",
        link: "/Apartments&Condo-Rendering-Services",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/All/5.Exterior-3D-Rendering-High-End-Penthouse-Pool-Beach-Miami.webp",
        name: "Summa",
        location: "Miami, Florida",
        usage: "Marketing Campaign",
        tags: "Exterior pool, New Development, 3D Rendering, 3D Visualization, CGI, Condominium",
        link: "/Penthouse-Rendering-Services",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/All/6.Interior-3D-Rendering-High-End-Penthouse-New-York.webp",
        name: "515 Park Avenue",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Penthouse, Interior Design, 3D Rendering, 3D Visualization, CGI, Apartment",
        link: "/Penthouse-Rendering-Services",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/All/6A.Exterior-3D-Rendering-High-End-Cabin-Upstate-New-York.webp",
        name: "Upstate Cabin",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Exterior Design, High-End, 3D Rendering, 3D Visualization, CGI",
        link: "/Cabin-Rendering-Services",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/All/7.Interior-3D-Rendering-High-End-Kitchen-New-York.webp",
        name: "Molteni&C",
        location: "New York, New York",
        usage: "Digital Catalog",
        tags: "Kitchen, Interior Design, 3D Rendering, 3D Visualization, CGI, Comercial",
        link: "/Kitchen-Rendering-Services",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/All/8.Interior-3D-Rendering-High-End-Kitchen-New-York.webp",
        name: "Molteni&C",
        location: "New York, New York",
        usage: "Digital Catalog",
        tags: "Kitchen, Interior Design, 3D Rendering, 3D Visualization, CGI, Comercial",
        link: "/Kitchen-Rendering-Services",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/All/9.Interior-3D-Rendering-High-End-Kitchen-New-York.webp",
        name: "Molteni&C",
        location: "New York, New York",
        usage: "Digital Catalog",
        tags: "Kitchen, Interior Design, 3D Rendering, 3D Visualization, CGI, Comercial",
        link: "/Kitchen-Rendering-Services",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/All/9A.Interior-3D-Rendering-High-End-Living-Room-Miami.webp",
        name: "Summa",
        location: "Miami, Florida",
        usage: "Marketing Campaign",
        tags: "High-End Living Room, Interior Design, 3D Rendering, 3D Visualization, CGI, Ocean View Condominium",
        link: "/Kitchen-Rendering-Services",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/All/9B.Interior-3D-Rendering-High-End-Living-Room-New-York.webp",
        name: "160 Lmlay Street",
        location: "Red Hook, New York",
        usage: "Digital Catalog",
        tags: "Loft, Interior Design, 3D Rendering, 3D Visualization, CGI, Condominium",
        link: "/Living-Room-Rendering-Services",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/All/9C.Interior-3D-Rendering-High-End-Bedroom-Miami.webp",
        name: "Summa",
        location: "Miami, Florida",
        usage: "Marketing Campaign",
        tags: "High-End Bedroom, Interior Design, 3D Rendering, 3D Visualization, CGI, Condominium",
        link: "/Bedroom-Rendering-Services",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/All/9D.Interior-3D-Rendering-High-End-Bedroom-Miami.webp",
        name: "Summa",
        location: "Miami, Florida",
        usage: "Marketing Campaign",
        tags: "High-End Bedroom, Interior Design, 3D Rendering, 3D Visualization, CGI, Condominium",
        link: "/Bedroom-Rendering-Services",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/All/10.Interior-3D-Rendering-High-End-Bathroom-New-York.webp",
        name: "Bathroom Renovation",
        location: "New York, New York",
        usage: "Renovation | Presentation | Planning",
        tags: "Bathroom, Interior Design, 3D Rendering, 3D Visualization, CGI, Residential",
        link: "/Bathroom-Rendering-Services",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/All/11.Interior-3D-Rendering-High-End-Bathroom-New-York.webp",
        name: "Hotel Boutique",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "High-End Bathroom, Interior Design, 3D Rendering, 3D Visualization, CGI, Hospitality",
        link: "/Bathroom-Rendering-Services",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/All/12.Interior-3D-Rendering-High-End-Amenities-Pool-New-York.webp",
        name: "Upper East Side Spa",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Spa, Interior Pool, Interior Design, 3D Rendering, 3D Visualization, Amenities, CGI",
        link: "/Amenities-Rendering-Services",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/All/13.Interior-3D-Rendering-High-End-Amenities-New-York.webp",
        name: "Alta LIC",
        location: "Long Island City, New York",
        usage: "Marketing Campaign",
        tags: " Condominium, Interior Design, 3D Rendering, 3D Visualization, Amenities, CGI",
        link: "/Amenities-Rendering-Services",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/All/14.Exterior-3D-Rendering-High-End-Roof-Garden-New-York.webp",
        name: "The Rooftop Lounge",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Interior Design, Roof Garden, 3D Rendering, 3D Visualization, CGI",
        link: "/Roof-Garden-Rendering-Services",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/All/15.Exterior-3D-Rendering-High-Roof-Garden-New-York.webp",
        name: "875 Avenue of the Americas",
        location: "New York, New York",
        usage: "Marketing Campaign | Presentation",
        tags: "Mixed-Use Development, 3D Rendering, 3D Visualization, CGI, Commercial Rooftop",
        link: "/Roof-Garden-Rendering-Services",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/All/16.Interior-3D-Rendering-High-End-Hospitality-Lobby-New-York.webp",
        name: "Iconic Hotel",
        location: "Abu Dhabi, UAE",
        usage: "Marketing Campaign",
        tags: "Lobby, Interior Design, 3D Rendering, 3D Visualization, Hotel, CGI, Hospitality",
        link: "/Hospitality-Rendering-Services",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/All/17.Exterior-3D-Rendering-Hospitality-Hotel-Pool-Miami.webp",
        name: "Club Med",
        location: "Cancun, Mexico",
        usage: "Marketing Campaign",
        tags: "Exterior pool, Interior Design, 3D Rendering, 3D Visualization, CGI, Hospitality",
        link: "/Hospitality-Rendering-Services",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/All/18.Interior-3D-Rendering-High-End-Restaurant-New-York.webp",
        name: "Harbour Club",
        location: "Charleston, South Carolina",
        usage: "Marketing Campaign",
        tags: "Interior Design, 3D Rendering, 3D Visualization, CGI, High-End Restaurant",
        link: "/Restaurant-Rendering-Services",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/All/19.Interior-3D-Rendering-High-End-Restaurant-New-York.webp",
        name: "Pera Soho",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Interior Design, 3D Rendering, 3D Visualization, CGI, High-End Restaurant",
        link: "/Restaurant-Rendering-Services",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/All/19A.Exterior-3D-Rendering-High-End-Comercial-Space-New-York.webp",
        name: "Brooklyn Eatery",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Retail, Commercial Space, 3D Rendering, 3D Visualization, CGI, Commercial",
        link: "/Commercial-Space-Rendering-Services",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/All/19B.Exterior-3D-Rendering-High-End-Mixed-Use-New-York.webp",
        name: "875 Avenue of the Americas",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Mixed-Use Development, 3D Rendering, 3D Visualization, CGI, Commercial, Retail",
        link: "/Mixed-Use-Rendering-Services",
      },
      {
        id: 29,
        url: "/assets/img/services/Galeria/Rendering/All/20.Exterior-3D-Rendering-High-End-Retail-New-York.webp",
        name: "793 Madison Ave",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Retail, Exterior Facade, 3D Rendering, 3D Visualization, CGI",
        link: "/Retail-Rendering-Services",
      },
      {
        id: 30,
        url: "/assets/img/services/Galeria/Rendering/All/20A.Interior-3D-Rendering-High-End-Retail-New-York.webp",
        name: "793 Madison Ave",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Retail, Interior Design, 3D Rendering, 3D Visualization, CGI",
        link: "/Retail-Rendering-Services",
      },
      {
        id: 31,
        url: "/assets/img/services/Galeria/Rendering/All/20B.Interior-3D-Rendering-High-End-Retail-Oculus-Center-Calatrava-New-York.webp",
        name: "Oculus Center WTC",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Interior, Retail, Interior Design, 3D Rendering, 3D Visualization, CGI, Santiago Calatrava",
        link: "/Retail-Rendering-Services",
      },
      {
        id: 32,
        url: "/assets/img/services/Galeria/Rendering/All/21A.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "550 Fifth Avenue",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Interior Design, 3D Rendering, 3D Visualization, CGI, Office Space",
        link: "/Office-Space-Rendering-Services",
      },
      {
        id: 33,
        url: "/assets/img/services/Galeria/Rendering/All/22A.Interior-3D-Rendering-Office-Space-New-York.webp",
        name: "550 Fifth Avenue",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Interior Design, 3D Rendering, 3D Visualization, CGI, Office Space",
        link: "/Office-Space-Rendering-Services",
      },
      {
        id: 34,
        url: "/assets/img/services/Galeria/Rendering/All/23.Exterior-3D-Rendering-High-End-Real-Estate-Development-New-York.webp",
        name: "Market Street",
        location: "Philadelphia City",
        usage: "Press Release | Presentation | Planning",
        tags: "Exterior, 3D Rendering, 3D Visualization, CGI, Real Estate Development",
        link: "/Real-Estate-Rendering-Services",
      },
      {
        id: 35,
        url: "/assets/img/services/Galeria/Rendering/All/24.Construction-Site-Signage-DOB-NYC-Buildings-3D-Rendering-3DWorld.webp",
        name: "DOB Construction Site Signage",
        location: "New York, New York",
        usage: "Presentation | Planning",
        tags: "Construction, Signage, DOB, NYC Buildings, 3D Rendering, CGI",
        link: "/DOB-Signage-Rendering-Services",
      },
      {
        id: 36,
        url: "/assets/img/services/Galeria/Rendering/All/25.Exterior-3D-Rendering-High-End-Aerial-Urbanization-Skyscraper-Shenzhen.webp",
        name: "Cities of the Future",
        location: "Shenzhen, China",
        usage: "Presentation | Planning",
        tags: "Aerial, Urbanization, 3D Rendering, 3D Visualization, CGI",
        link: "/Aerial-Rendering-Services",
      },
      {
        id: 37,
        url: "/assets/img/services/Galeria/Rendering/All/26.Exterior-3D-Rendering-High-End-Aerial-Urbanization-New-York.webp",
        name: "Residential Development",
        location: "Austin, Texas",
        usage: "Presentation | Planning",
        tags: "Aerial, Urbanization, 3D Rendering, 3D Visualization, CGI",
        link: "/Aerial-Rendering-Services",
      },
      {
        id: 38,
        url: "/assets/img/services/Galeria/Rendering/All/27.Exterior-3D-Rendering-High-End-Event-New-York.webp",
        name: "The Margaret Event",
        location: "Hudson Valley, NY",
        usage: "Marketing Campaign | Presentation",
        tags: "Event Venue, Interior Design, 3D Rendering, 3D Visualization, CGI",
        link: "/Event-Rendering-Services",
      },
      {
        id: 39,
        url: "/assets/img/services/Galeria/Rendering/All/27A.Pop-Up-Event-3D-Rendering-New-York.webp",
        name: "Arizona 30th Anniversary",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Pop Up, 3D Rendering, 3D Visualization, CGI, Event",
        link: "/Event-Rendering-Services",
      },
      {
        id: 40,
        url: "/assets/img/services/Galeria/Rendering/All/27B.Interior-3D-Rendering-High-End-School-New-York.webp",
        name: "Riverdale Country School",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Interior Design, 3D Rendering, 3D Visualization, CGI, School",
        link: "/School-Rendering-Services",
      },
      {
        id: 41,
        url: "/assets/img/services/Galeria/Rendering/All/27C.Exterior-3D-Rendering-High-End-Car-dealership-New-York.webp",
        name: "KIA Motors Dealership",
        location: "LATAM",
        usage: "Marketing Campaign",
        tags: "Interior Design, 3D Rendering, 3D Visualization, CGI, Car Dealership",
        link: "/Car-Dealership-Rendering-Services",
      },
      {
        id: 42,
        url: "/assets/img/services/Galeria/Rendering/All/28.Metaverse-3D-Rendering-New-York.webp",
        name: "The Schmotel Hotel",
        location: "The Metaverse",
        usage: "Marketing Campaign",
        tags: "Metaverse, 3D Rendering, 3D Visualization, CGI, Everyrealm",
        link: "/Metaverse-Rendering-Services",
      },
      {
        id: 43,
        url: "/assets/img/services/Galeria/Rendering/All/29.Exterior-3D-Rendering-Synagogue-Miami.webp",
        name: "Bal Harbour Synagogue",
        location: "Miami, Florida",
        usage: "Marketing Campaign",
        tags: "Exteror Design, 3D Rendering, 3D Visualization, CGI, Religious buildings",
        link: "/Religious-Rendering-Services",
      },
      {
        id: 44,
        url: "/assets/img/services/Galeria/Rendering/All/29A.Interior-3D-Rendering-Synagogue-New-York.webp",
        name: "Bal Harbour Synagogue",
        location: "Miami, Florida",
        usage: "Marketing Campaign",
        tags: "Interior Design, 3D Rendering, 3D Visualization, CGI, Religious buildings",
        link: "/Religious-Rendering-Services",
      },
      {
        id: 45,
        url: "/assets/img/services/Galeria/Rendering/All/30.Exterior-Environment-Product-Design-3D-Rendering-New-York.webp",
        name: "Wings of Steel",
        location: "The Hamptons",
        usage: "Digital Catalog",
        tags: "Product Design, 3D Environment, 3D Rendering, 3D Visualization, CGI",
        link: "/Product-Design-Rendering-Services",
      },
      {
        id: 46,
        url: "/assets/img/services/Galeria/Rendering/All/31.Product-Design-3D-Rendering-Razor-New-York.webp",
        name: "Athena Club",
        location: "New York, New York",
        usage: "Digital Catalog",
        tags: "Product Design, Razor, 3D Rendering, 3D Visualization, CGI",
        link: "/Product-Design-Rendering-Services",
      },
      {
        id: 47,
        url: "/assets/img/services/Galeria/Rendering/All/31A.Product-Design-3D-Rendering-Bottle-New-York.jpg",
        name: "Twenty Brand",
        location: "New York, New York",
        usage: "Digital Catalog",
        tags: "Product Design, Bottle, 3D Rendering, 3D Visualization, CGI",
        link: "/Product-Design-Rendering-Services",
      },
      {
        id: 48,
        url: "/assets/img/services/Galeria/Rendering/All/32.Product-Design-3D-Rendering-Ice-Cream-Catalog-New-York.webp",
        name: "Dairy Queen",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Product Design, 3D Rendering, 3D Visualization, CGI",
        link: "/Product-Design-Rendering-Services",
      },
      {
        id: 49,
        url: "/assets/img/services/Galeria/Rendering/All/33.Retail-Store-Display-3D-Rendering-Sperry-Footwear-New-York.webp",
        name: "Sperry Top-Sider",
        location: "New York, New York",
        usage: "Digital Catalog",
        tags: "Product Design, 3D Footwear, Retail, 3D Rendering, 3D Visualization, CGI",
        link: "/Product-Design-Rendering-Services",
      },
      {
        id: 50,
        url: "/assets/img/services/Galeria/Rendering/All/34.Axonometric-3D-Rendering-Layout-Realtor-Sales-Tools-New-York.webp",
        name: "One Essex",
        location: "New York, New York",
        usage: "Marketing Campaign",
        tags: "Interior Design, 3D Rendering, Axonometric, CGI Layout, Sales Tool",
        link: "/Axonometric-Rendering-Services",
      },
      {
        id: 51,
        url: "/assets/img/services/Galeria/Rendering/All/35.Exterior-Hand-Drawn-Rendering-Dubai-New-York.webp",
        name: "Palm Islands",
        location: "Dubai, UAE",
        usage: "Marketing Campaign",
        tags: "Exterior, Hand Drawn Rendering, Illustration",
        link: "/Hand-Drawn-Rendering-Services",
      },
    ],
  },
  {
    category: "Residential",
    path: "/Residential-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Residential/0. Exterior-3D-Rendering-Residential-High-End-Pool-House-New-York-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Residential/1A.Exterior-3D-Rendering-Residential-High-End-Upstate-New-York-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Residential/1B.Exterior-3D-Render-secondhome-High-End-New-York-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Residential/2.Exterior-3D-Rendering-Residential-Brownstone-New-York-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Residential/4.Exterior-3D-Rendering-Residential-High-End-House-Austin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Residential/5.Exterior-High-End-Residential-3D-Rendering-New-York-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Residential/6.Exterior-3D-Rendering-Residential-High-End-New-york-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Residential/7. Residential-Interior-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Residential/8. Residential-Interior-3D-Rendering-Residential-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Residential/9. Residential-Interior-3D-Rendering-Interior-design-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Residential/10.Residential-Interior-3D-Rendering-Interior-design-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Residential/11.Residential-Interior-3D-Render-Livingroom-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Residential/12. Residential-Interior-3D-Rendering-Interior-Design-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Residential/13. Residential-Interior-3d-Rendering-Apartment-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Residential/14.Residential-Interior-3D-Rendering-Apartment-CGI-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Residential/15.Interior-Loft-3D-render-Visualization-New-York-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Residential/16.Interior-3D-Rendering-Interior-design-New-York-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Residential/17.Interior-3D-Render-Kitchen-Visualization-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Residential/18.Exterior-3D-Rendering-Brooklyn-Terrace-Residential-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Residential/19.Interior-3D-Rendering-Residential-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Residential/20.Interior-3D-Rendering-Residential-Interior-design-High-End-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Residential/21.Interior-3D-Rendering-Interior-design-CGI-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Residential/22.Interior-3D-Rendering-Interior-Design-CGI-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Residential/23.Exterior-3D-Render-Residential-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Residential/24.Interior-3D-Rendering-High-End-Pool-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Residential/25.Exterior-3D-Rendering-High-End-Pool-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Residential/26.Interior-3D-Render-Interior-Visualization-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Residential/27.Interior-3D-Rendering-Residential-Livingroom-Netflix-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 29,
        url: "/assets/img/services/Galeria/Rendering/Residential/28.Interior-Residential-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 30,
        url: "/assets/img/services/Galeria/Rendering/Residential/29.Interior-Residential-Interior-Design-Visualization-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 31,
        url: "/assets/img/services/Galeria/Rendering/Residential/30.Interior-Rendering-3D-Visualization-Abu-Dhabi-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 32,
        url: "/assets/img/services/Galeria/Rendering/Residential/31.Interior-3D-Render-Bedroom-MurphyBed-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 33,
        url: "/assets/img/services/Galeria/Rendering/Residential/32.Interior-3D-Rendering-Bedroom-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 34,
        url: "/assets/img/services/Galeria/Rendering/Residential/33.Interior-3D-Rendering-Livingroom-MurphyBed-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 35,
        url: "/assets/img/services/Galeria/Rendering/Residential/34. Residential-Interior-Townhouse-3D-Rendering-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 36,
        url: "/assets/img/services/Galeria/Rendering/Residential/35.Residential-Interior-Ocean-view-3D-Rendering-Miami-Beach.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 37,
        url: "/assets/img/services/Galeria/Rendering/Residential/36.Interior-Residential-3D-Rendering-High-End-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 38,
        url: "/assets/img/services/Galeria/Rendering/Residential/37.Residential-Exterior-Facade-3D-Rendering-High-End-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 39,
        url: "/assets/img/services/Galeria/Rendering/Residential/38.Residential-Exterior-3D-Rendering-Cabin-Winter-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 40,
        url: "/assets/img/services/Galeria/Rendering/Residential/39.Residential-Exterior-Facade-Nightshoot-3D-Rendering-High-End.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 41,
        url: "/assets/img/services/Galeria/Rendering/Residential/40.Residential-Exterior-Grill-Terrace-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 42,
        url: "/assets/img/services/Galeria/Rendering/Residential/41.Residential-Exterior-3D-Rendering-High-End-Backyard-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 43,
        url: "/assets/img/services/Galeria/Rendering/Residential/42.Residential-Exterior-Backyard-3D-Render-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 44,
        url: "/assets/img/services/Galeria/Rendering/Residential/43.Residential-Exterior-3D-Rendering-Backyard-Garden-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 45,
        url: "/assets/img/services/Galeria/Rendering/Residential/44.Residential-Interior-3D-Rendering-Homeoffice-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 46,
        url: "/assets/img/services/Galeria/Rendering/Residential/44A.Residential-Interior-3D-Rendering-Bedroom-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 47,
        url: "/assets/img/services/Galeria/Rendering/Residential/45.Residential-Exterior-3D-Rendering-Garden-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 48,
        url: "/assets/img/services/Galeria/Rendering/Residential/46.Residential-Exterior-3D-Render-Palmtrees-CGI-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Brownstone",
    path: "/Brownstone-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/0.Exterior-Brownstone-3D-Rendering-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/1.Exterior-Brickwall-Brownstone-3D-Render-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/2.Exterior-Brickwall-Brownstone-3D-Render-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/3.Brownstone-Exterior-Brickwall-3D-Rendering-Mixed-Used-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/4.Brownstone-Exterior-3D-Rendering-New-York-Street.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/5.Exterior-Brownstone-Terrace-3D-Render-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/6.Brownstone-Exterior-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/7.Brownstone-Exterior-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/8.Townhouse-Exterior-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/9.Brownstone-Exterior-3D-Render-Roofgarden-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/10.Brownstone-Exterior-3D-Render-Commercial-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Brownstone/11.Brownstone-Exterior-3D-Rendering-Brickwall-Gallery-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Apartments & Condos",
    path: "/Apartments&Condo-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/0.Interior-Modern-Apartment-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/1.Interior-Modern-Apartment-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/2.Interior-Modern-Apartment-3D-Render-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/3.Interior-Apartment-3D-Rendering-CGI-Visualization-RedHook-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/4.Interior-Apartment-3D-Rendering-Visualization-RedHook-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/5.Interior-Loft-3D-Render-Visualization-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/6.Interior-Apartment-Render-Bedroom-Visualization-MurphyBed-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/7.Interior-Apartment-3D-Rendering-CGI-MurphyBed-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/8.Interior-Condominium-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/9.Small-Interior-Apartment-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/10.Micro-unit-Interior-Apartment-3D-Render-MurphyBed-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/11.Interior-Condo-3D-Render-MurphyBed-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/11B.Interior-Design-Condominium-Cambells-Soup-3D-Render-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/12.Interior-Design-Apartment-3D-Render-NFL-High-End-New-Jersey.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/13.Interior-Design-Apartment-3D-Render-High-End-New-Jersey.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/14.Interior-Loft-3D-Render-Visualization-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/15.Interior-Design-Apartment-3D-Rendering-Visualization-CGI-New-Jersey.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/16.Exterior-Apartment-3D-Render- Visualization-CGI-Austin-Texas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/17.Exterior-Condominium-Pool-area-Rendering-Skyview-Bermudas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/2A.Condo&Apartments/18.Exterior-Facade-Condominium-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Penthouse",
    path: "/Penthouse-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/0.Modern-Exterior-Penthouse-3D-Render-Beach-Front-Collins-Avenue-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/1.Modern-Exterior-Penthouse-3D-Render-Beach-Pool-view-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/2.Modern-High-End-Exterior-Penthouse-3D-Render-Beach-Front-Collins-Avenue-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/3.High-End-Modern-Interior-Penthouse-3D-Render-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/4-High-End-Modern-Interior-Penthouse-3D-Render-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/5.Modern-Interior-Penthouse-3D-Render-Ocean-View-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/6.Modern-Exterior-Penthouse-3D-Render-Los-Angeles-LA.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/7.Modern-Interior-Penthouse-3D-Render-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/8.High-end-Modern-Interior-Penthouse-3D-Render-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/9.High-End-Modern-Exterior-Penthouse-3D-Render-Red-Hook-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/10.High-End-Modern-Interior-Penthouse-3D-Render-Red-Hook-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/11.High-End-Modern-Exterior-Penthouse-3D-Render-Red-Hook-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/12.High-End-Modern-Interior-Penthouse-3D-Render-Park-Avenue-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/13.High-End-Modern-Exterior-Penthouse-3D-Render-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/14.Modern-High-End-Exterior-Penthouse-3D-Render-Beach-Front-Collins-Avenue-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/15.Modern-High-End-Exterior-Penthouse-3D-Render-Beach-Front-Collins-Avenue-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Penthouse/16.Modern-High-End-Interior-Penthouse-3D-Render-Beach-Front-Collins-Avenue-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Cabin",
    path: "/Cabin-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Cabin/0.Exterior-Cabin-3D-Rendering-Forest.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Cabin/1.Exterior-Cabin-3D-Render-Woods-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Cabin/2.Exterior-Cabin-3D-Render-Forest-Spain.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Cabin/2A.Tropical-Exterior-Cabin-3D-Render-Bermudas-Night-Shoot.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Cabin/3A.Tropical-Exterior-Cabin-3D-Rendering-Holbox.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Cabin/4A.Tropical-Interior-Cabin-3D-Render-Holbox.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Cabin/5.Tropical-Exterior-Cabin-3D-Render-Hawai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Cabin/5A.Tropical-Exterior-Cabin-3D-Rendering.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Cabin/5B.Exterior-Cabin-3D-Render-Forest-Canada.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Cabin/5C.Exterior-Cabin-3D-Render-Woods-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Cabin/5D.Exterior-Cabin-Camping-3D-Render-Upstate-New-York-Night-view.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Cabin/6.Exterior-Cabin-3D-Render-Connecticut.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Cabin/7.Exterior-Cabin-3D-Render-Forest-Massachusetts.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Kitchen",
    path: "/Kitchen-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/0.Interior-3D-Rendering-High-End-Kitchen-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/1A.Interior-3D-Rendering-High-End-Kitchen-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/2.Interior-3D-Rendering-High-End-Kitchen-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/3.Interior-3D-Rendering-High-End-Kitchen-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/4.Interior-3D-Render-Kitchen-CGI-Los-Angeles-LA.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/5.Interior-3D-Render-Kitchen-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/6.Interior-3D-Render-Kitchen-High-End-Micro-Unit-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/7.Interior-3D-Rendering-Kitchen-High-End-Collins-Avenue-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/8.Interior-3D-Rendering-High-End-Kitchen-Visualization-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/9.High-End-Interior-3D-Render-Livingroom-Kitchen-CGI-Park-Avenue-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/10.Interior-3D-Rendering-Kitchen-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/11.Interior-3D-Render-White-Granite-Close-up-Kitchen-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/12.Interior-3D-Rendering-Kitchen-High-End-New-Micro-Unit-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/13.Small-Interior-3D-Render-Kitchen-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/14.Interior-3D-Render-Kitchen-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/15.Interior-3D-Render-Oven-Kitchen-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/16.Interior-3D-Render-Kitchen-CGI-Up-state-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/17.Interior-3D-Rendering-Kitchen-Black-Counter-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/18.Interior-3D-Render-Kitchen-CGI-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/19.Interior-3D-Rendering-Kitchen-Black-Counter-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/20.Minimalist-Interior-Kitchen-3D-Render-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/21.Interior-Kitchen-3D-Render-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/22.Interior-3D-Rendering-Kitchen-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/23.Interior-3D-Render-Kitchen-Townhouse-Visualization-CGI-Brooklyn.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/24.Interior-3D-Render-Kitchen-CGI-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Kitchen/25.Interior-3D-Render-Kitchen-White-Granite-CGI-Napa-Winery.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Living Room",
    path: "/Living-Room-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/0.Interior-Livingroom-3D-Render-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/1.Interior-3D-Render-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/2.Interior-3D-Rendering-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/3.Interior-3D-Rendering-Visualization-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/4.Interior-3D-Rendering-Visualization-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/5.Interior-Livingroom-3D-Render-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/6.Interior-Livingroom-Aparment-3D-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/7.Interior-3D-Render-Visualization-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/8.Interior-3D-Rendering-Visualization-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/9.Interior-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/10.Interior-Livingroom-3D-Render-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/11.Interior-Livingroom-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/12.Interior-Livingroom-3D-Render-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/13.Interior-Livingroom-3D-Render-CGI-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/14.Interior-Living-Room-3D-Render-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/15.Interior-3D-Render-CGI-High-Resolution-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/16.Interior-Livingroom-White-CGI-Visualization-Render-3D-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/17.Interior-Livingroom-3D-Rendering-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/18.Interior-Livingroom-3D-Room-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/19.Interior-3D-Render-CGI-Livingroom-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/20.Interior-3D-Rendering-CGI-Livingroom-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/21.Interior-3D-Render-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/22.Interior-3D-Render-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/23.Interior-3D-Render-High-Books-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Living-Room/24.Residential-Interior-Townhouse-3D-Rendering-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Bedroom",
    path: "/Bedroom-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/0.Interior-Bedroom-3D-Rendering-High-End-Miami-Florida.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/1.Interior-Bedroom-3DRender-CGI-Miami-Florida.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/2.Interior-Bedroom-3D-Rendering-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/2A.Interior-Bedroom-3D-Render-High-end-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/3.Interior-Bedroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/4.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/4A.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/4B.Interior-Bedroom-Gray-Colors-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/13.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/16.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/17.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/22.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/24.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/28.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/30.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/31.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/32.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/33.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/35.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/36.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/37.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/39.Interior-Bedroom-3D-Render-High-end-Saudi-Arabia.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/42.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/48.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/49.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/49B.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/56.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/57.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 29,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/58.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 30,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/59.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 31,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/61.Interior-Bedroom-3D-Render-High-end-New-york.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 32,
        url: "/assets/img/services/Galeria/Rendering/Bedroom/62.Interior-Bedroom-3D-Render-High-end-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Bathroom",
    path: "/Bathroom-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/0.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/1.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/2.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/3.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/4.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/5.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/6.Interior-Bathroom-3D-Rendering-High-End-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/7.Interior-Bathroom-3D-Rendering-High-End-Paris.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/8.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/9.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/10.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/11.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/12.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/13.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/14.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/15.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/16.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/17.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/18.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/19.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/20.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/21.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/22.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/23.Interior-Bathroom-3D-Rendering-High-End-Ocean-View-Collins-Avenue.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/24.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/25.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/26.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Bathroom/27.Interior-Bathroom-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Amenities",
    path: "/Amenities-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Amenities/1.Interior-Amenities-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Amenities/2.Interior-Amenities-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3B.Interior-Amenities-3D-Rendering-Luxury-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3C.Exterior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3D.Exterior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3E.Exterior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3F.Exterior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3G.Interior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3H.Interior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3I.Interior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3J.Exterior-Amenities-3D-Redenring-High-End-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Amenities/3K.Interior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Amenities/4.Interior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Amenities/4A.Interior-Amenities-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Amenities/4B.Interior-Amenities-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Amenities/4C.Interior-Amenities-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Amenities/5.Interior-Amenities-Furniture-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Amenities/6.Interior-Amenities-Pool-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Amenities/6A.Interior-Amenities-Bascket-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Amenities/23.Interior-Amenities-3D-Rendering-Visualization-Gym-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Amenities/24.Interior-Amenities-3D-Rendering-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Amenities/25.Exterior-Amenities-3D-Rendering-Visualization-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Roof Garden",
    path: "/Roof-Garden-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/0.Exterior-Roof-Garden-3D-Rendering-High-End-Empire-State-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/2.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/3.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/4.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/4A.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/5.Exterior-Roof-Garden-3D-Rendering-High-End-Pool-Los-Angeles-LA.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/6.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/7.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/8.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/9.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/10.Exterior-Roof-Garden-3D-Rendering-High-End-Ocean-View-Barbados.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/11.Exterior-Roof-Garden-3D-Rendering-High-End-Pool-Ocean-View-Barbados.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/12.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Roof-Garden/13.Exterior-Roof-Garden-3D-Rendering-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Hospitality",
    path: "/Hospitality-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/1.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/2.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/2b.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/3C.Exterior-3D-Rendering-High-End-Hospitality-CGI-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/3D.Exterior-3D-Rendering-High-End-Hospitality-CGI-Bahamas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/4.Interior-3D-Rendering-High-End-Hospitality-CGI-Los-Angeles.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/4B.Interior-3D-Rendering-High-End-Hospitality-CGI-Los-Angeles.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/5.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/6.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/7.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/8.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/9.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/9b.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/10.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/11.Interior-3D-Rendering-High-End-Hospitality-CGI-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/12.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/13.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/14.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/15.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/16.Exterior-3D-Rendering-Palm-Bahamas-Hopitality.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/17.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/18.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/19. Exterior-3D-Rendering-Hospitality-Hotel-Pool-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/20.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/21.Interior-3D-Rendering-Hospitality-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/22.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/23.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/24.Exterior-3D-Rendering-Hospitality-Miami-Restauran.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 29,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/25.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 30,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/26.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 31,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/27.Exterior-3D-Rendering-Hospitality-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 32,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/28.Exterior-3D-Rendering-Hospitality-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 33,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/29.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 34,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/30.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 35,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/31.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 36,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/32.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 37,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/33.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 38,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/34.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 39,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/35Interior-3D-Rendering-Hospitality-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 40,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/36.Exterior-3D-Rendering-Hospitality-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 41,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/37.Interior-3D-Rendering-Hospitality-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 42,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/38.Interior-3D-Rendering-Hospitality-Lobby-Duabi.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 43,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/39.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 44,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/40.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 45,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/41.Exterior-3D-Rendering-Hospotality-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 46,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/42.Interior-3D-Rendering-Hospitality-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 47,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/43.Interior-3D-Rendering-Hospitality-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 48,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/44.Interior-3D-Rendering-Hospitality-TCR-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 49,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/45.Interior-Chiffon-Lobby-3D-Rendering- Hospitality-Florida.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 50,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/46.Interior-Beerhive-Hospitality-3D-Renderings-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 51,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/47.Exterior-3D-Rendering-Meridien-Hospitality-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 52,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/48.Exterior-Microtel-Hotel- Hospitality-Renderings-3D.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 53,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/49.Exterior-3D-Rendering-Myrtle-Hospitality.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 54,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/50.Exterior-Militida-Hospitality-3D-Renderings-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 55,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/51.EXterior-View-Hospitality-3D-Renderings.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 56,
        url: "/assets/img/services/Galeria/Rendering/Hospitality/52.Exterior-Palm-Desert-Hospitality-3D-Renderings-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Restaurant",
    path: "/Restaurant-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/1.Interior-3D-Rendering-High-End-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/2.Interior-3D-Rendering-Coffee-Shop-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/3.Interior-3D-Rendering-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/4.Interior-3D-Rendering-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/5.Interior-3D-Rendering-Restaurant-Buffet-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/6.Interior-3D-Rendering-Coffee-Shop-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/7.Interior-3D-Rendering-Coffe-Shop-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/8.Interior-3D-Rendering-Red-Room-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/9.Interior-3D-Rendering-High-End-Restaurant-American-Flag-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/10.Interior-3D-Rendering-High-End-Restaurant-Lake-View.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/11.Interior-3D-Rendering-High-End-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/12.Interior-Mederian-Restaurant-3D-Rendering-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/13.Interior-Restaurant-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/14.Exterior-Restaurant-3D-Rendering-High-End-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/15.Interior-Restaurant-3D-Rendering-High-End-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/16.Interior-3D-Rendering-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/17.Exterior-3D-Rendering-Beach-Restaurant-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/18.Exterior-3D-Rendering-Beach-View-Restaurant-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/19.Exterior-3D-Rendering-Beach-View-Restaurant-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/20.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/21.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/22.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/23.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/24.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/25.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/26.Interior-3D-Restaurant-Bar-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/27.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/28.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 29,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/29.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 30,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/30.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 31,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/31.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 32,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/32.Interior-3D-Restaurant-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 33,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/33.Interior-3D-Restaurant-Rendering-Ocean-View-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 34,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/34.Exterior-3D-Rendering-Restaurant-Medirian-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 35,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/35.Interior-3D-Rendering-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 36,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/36.Interior-3D-Restaurant-Bar-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 37,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/37.Interior-3D-Rendering-Restaurant-Bar-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 38,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/38.Interior-3D-Rendering-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 39,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/39.Interior-3D-Rendering-Restaurant-Bar-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 40,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/40.Interior-3D-Rendering-Restaurant-Hotel-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 41,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/41.Interior-3D-Rendering-Coffee-Shop-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 42,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/42.Exterior-3D-Rendering-Lake-View-Restaurant-High-End.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 43,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/43.Interior-3D-Rendering-Restaurant-Bar-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 44,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/44.Interior-3D-Rendering-Restaurant-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 45,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/45.Interior-3D-Rendering-Restaurant-Coffee-Shop-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 46,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/46.Interior-3D-Rendering-Restaurant-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 47,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/47.Interior-3D-Rendering-Restaurant-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 48,
        url: "/assets/img/services/Galeria/Rendering/Restaurant/48.Interior-3D-Rendering-Restaurant-Hotel-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Commercial Space",
    path: "/Commercial-Space-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/1.Exterior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/1b.Exterior-3D-Rendering-Shopping-Mall-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/1c.Exterior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/2.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/3.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/4.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/5.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/6.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/7.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/7b.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/8.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/8A.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/9.Exterior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/10.Interior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/11.Exterior-3D-Rendering-Commercial-Space-Gas-Station-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/12.Exterior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/13.Interior-3D-Render-Porsche-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/14.Exterior-3D-Rendering-Commercial-Space-Casino-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/14b.Exterior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/14c.Exterior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/15.Exterior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/16.Exterior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/17.Exterior-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/18.Exterior-Facade-3D-Rendering-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/19.Interior-3D-Render-Commercial-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/20.Interior-3D-Render-Commercial-Space-Bank-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Commercial-Space/21.Exterior-3D-Render-Commercial-Space-Beach-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Mixed Use",
    path: "/Mixed-Use-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/1.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/2.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/3.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/4.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/5.Exterior-3D-Rendering-Mixed-Use-High-End-LATAM.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/6.Exterior-3D-Rendering-Mixed-Use-High-End-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/7.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/8.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/9.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/10.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/11.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/12.Exterior-3D-Rendering-Mixed-Use-High-End-Shenzhen.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/13. Interior-3D-Rendering-High-End-Mixed-Use-Oculus-Center-Calatrava-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/14.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/15.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/16.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/17.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/18.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/19.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/20.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/21.Exterior-3D-Rendering-Mixed-Use-High-End-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/22.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/23.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/24.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/25.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/26.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/27.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Mixed-Use/28.Exterior-3D-Rendering-Mixed-Use-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Retail",
    path: "/Retail-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Retail/00.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Retail/1.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Retail/2.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Retail/3.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Retail/3A.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Retail/3B.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Retail/4.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-GFT-Shop-Metaverse-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Retail/4A.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Retail/5.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Retail/5A. Interior-3D-Rendering-High-End-Retail-Oculus-Center-Calatrava-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Retail/6A.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-Abu-dhabi.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Retail/7.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Retail/8.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Retail/9.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Retail/10.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Retail/11.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Retail/12.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Retail/14.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Retail/15.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Retail/16.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Retail/17.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Retail/19.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Retail/20.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Retail/21.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Retail/22.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Retail/23.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Retail/24.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Retail/25.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 29,
        url: "/assets/img/services/Galeria/Rendering/Retail/26.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 30,
        url: "/assets/img/services/Galeria/Rendering/Retail/27.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 31,
        url: "/assets/img/services/Galeria/Rendering/Retail/28.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 32,
        url: "/assets/img/services/Galeria/Rendering/Retail/29.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 33,
        url: "/assets/img/services/Galeria/Rendering/Retail/30.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 34,
        url: "/assets/img/services/Galeria/Rendering/Retail/31.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 35,
        url: "/assets/img/services/Galeria/Rendering/Retail/32.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 36,
        url: "/assets/img/services/Galeria/Rendering/Retail/33.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 37,
        url: "/assets/img/services/Galeria/Rendering/Retail/34.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 38,
        url: "/assets/img/services/Galeria/Rendering/Retail/35.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 39,
        url: "/assets/img/services/Galeria/Rendering/Retail/36.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 40,
        url: "/assets/img/services/Galeria/Rendering/Retail/37.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-Paris.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 41,
        url: "/assets/img/services/Galeria/Rendering/Retail/38.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 42,
        url: "/assets/img/services/Galeria/Rendering/Retail/39.Exterior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 43,
        url: "/assets/img/services/Galeria/Rendering/Retail/40.Interior-3D-Rendering-Visualization-Retail-CGI-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Office Space",
    path: "/Office-Space-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/1.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/2.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/3.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/4.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/5.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/6.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/7.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/8.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/9.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/10.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/11.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/12.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/13.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/14.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/15.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/16.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/17.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/18.Exterior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/19.Interior-3D-Rendering-High-End-Office-Space-Abu-Dhabi.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/20.Interior-3D-Renderings-High-End-Office-Space-Abu-Dhabi.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/21.Interior-3D-Rendering-High-End-Office-Space-Abu-Dhabi.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/22.Interior-3D-Rendering-High-End-Office-Space-Abu-Dhabi.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/23.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/24.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/25.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/26.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/27.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/28.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 29,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/29.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 30,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/30.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 31,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/31.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 32,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/32.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 33,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/33.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 34,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/34.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 35,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/35.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 36,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/36.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 37,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/37.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 38,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/38.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 39,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/39.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 40,
        url: "/assets/img/services/Galeria/Rendering/Office-Space/40.Interior-3D-Rendering-High-End-Office-Space-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Real Estate",
    path: "/Real-Estate-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/1.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/2.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/3.Exterior-3D-Render-Real-Estate-Visualization-Los-Angeles.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/4.Exterior-3D-Render-Real-Estate-Visualization-Los-Angeles.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/5.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/6.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/7.Exterior-3D-Render-Real-Estate-Visualization-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/8.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/9.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/10.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/11.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/12.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/13.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/14.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/15.Exterior-3D-Render-Real-Estate-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/16.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/17.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/18.Exterior-3D-Render-Real-Estate-Visualization-Miami-Florida.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/19.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/20.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/21.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/22.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/23.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/24.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/25.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/26.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/27.Exterior-3D-Render-Real-Estate-Visualization-Miami-Florida.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/28.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 29,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/29.Exterior-3D-Render-Real-Estate-Visualization-Miami-Florida.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 30,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/30.Exterior-3D-Render-Real-Estate-Visualization-Miami-Florida.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 31,
        url: "/assets/img/services/Galeria/Rendering/Real-Estate/31.Exterior-3D-Render-Real-Estate-Visualization-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "DOB Signage",
    path: "/DOB-Signage-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/DOB-Signage/0.Construction-Site-Signage-DOB-NYC-Buildings-Residential-3D-Rendering-3DWorld.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/DOB-Signage/1.Construction-Site-Signage-DOB-NYC-Buildings-Commercial-3D-Rendering-3DWorld.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/DOB-Signage/2.Construction-Site-Signage-DOB-NYC-Buildings-Mixed-Use-3D-Rendering-3DWorld.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/DOB-Signage/3.Construction-Site-Signage-DOB-NYC-Buildings-Commercial-3D-Rendering-3DWorld.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/DOB-Signage/4.Construction-Site-Signage-DOB-NYC-Buildings-Commercial-3D-Rendering-3DWorld.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/DOB-Signage/5.0.Construction-Site-Signage-DOB-NYC-Buildings-Residential-3D-Rendering-3DWorld.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Aerial",
    path: "/Aerial-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Aerial/00.Exterior-Aerial-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Aerial/01.Exterior-Aerial-3D-Render-Visualization-High-End-Austin-Texas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Aerial/01A.Exterior-Aerial-3D-Render-Visualization-High-End-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Aerial/02.Exterior-Aerial-3D-Render-Visualization-High-End-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Aerial/02B.Exterior-Aerial-3D-Render-Visualization-High-End-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Aerial/03.Exterior-Aerial-3D-Render-Visualization-High-End-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Aerial/7.Exterior-Aerial-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Aerial/8.Exterior-Aerial-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Aerial/9.Exterior-Aerial-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Aerial/10.Exterior-Aerial-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Aerial/11.Exterior-Aerial-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Aerial/12.Exterior-Aerial-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Aerial/13.Exterior-Aerial-3D-Render-Visualization-High-End-Austin-Texas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Aerial/15.Exterior-Aerial-3D-Render-Visualization-High-End-Autin-Texas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Aerial/17.Exterior-Aerial-3D-Render-Visualization-High-End-Austin-Texas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Aerial/18.Exterior-Aerial-3D-Render-Visualization-High-End-Austin-Texas.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Aerial/18A.Exterior-Aerial-3D-Render-Visualization-High-End-Land.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Aerial/18B.Exterior-Aerial-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Aerial/18C.Exterior-Aerial-3D-Render-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Aerial/19.Exterior-Aerial-3D-Render-Visualization-High-End-Miami-Florida.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Aerial/Z.Exterior-Aerial-3D-Render-Visualization-High-End-Concept-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Event",
    path: "/Event-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Event/0.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Event/1.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Event/2.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Event/3.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Event/4.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Event/5.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Event/6.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Event/7.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Event/8.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Event/9.Interior-Event-3D-Rendering-Ice-Arizona.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Event/10.Exterior-Event-3D-Rendering-High-End.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Event/11.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Event/12.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Event/13.Exterior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Event/14.Exterior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Event/15.Interior-Pop-Up-Event-3D-Rendering-Arizona.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Event/16.Interior-Event-3D-Rendering-Visualization-High-End-Arizona.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Event/17.Interior-Event-3D-Rendering-Visualization-High-End-Arizona.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Event/18.Interior-Event-3D-Rendering-Visualization-High-End-Arizona.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Event/19.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Event/20.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Event/21.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Event/22.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Event/23.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Event/24.Interior-Station-3D-Rendering-Visualization-Event-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Event/25.Exterior-Event-3D-Rendering-Visualization-High-End-Miami-Florida.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Event/26.Interior-Event-3D-Rendering-Visualization-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "School",
    path: "/School-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/School/1.Exterior-School-3D-Rendering-Visual-High-end-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/School/2.Interior-School-3D-Rendering-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/School/2A.Interior-School-3D-Rendering-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/School/2B.Interior-School-3D-Rendering-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/School/2C.Interior-School-3D-Rendering-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/School/2C1.Interior-School-3D-Rendering-Visualization-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/School/2C2.Interior-Pre-School-3D-Rendering-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/School/2D.Interior-School-3D-Rendering-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/School/3B.Exterior-School-3D-Rendering-Visual-High-end-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/School/4.Exterior-School-3D-Rendering-Visual-High-end-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/School/5.Interior-School-3D-Rendering-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/School/7.Interior-School-3D-Rendering-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/School/7A.Interior-School-3D-Rendering-Visualization-High-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/School/11.Exterior-School-3D-Rendering-Visual-High-end-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/School/12.Exterior-School-3D-Rendering-Visual-High-end-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/School/13.Interior-Culinary-School-3D-Rendering-Visual-High-end-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Car Dealership",
    path: "/Car-Dealership-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/1.Exterior-3D-Rendering-Visualization-Car-Dealership-LATAM.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/2.Exterior-3D-Rendering-Visualization-Car-Dealership-Mini-Cooper-Angeles.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/3.Exterior-3D-Rendering-Visualization-Car-Dealership-Angeles.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/4.Exterior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/5.Exterior-3D-Rendering-Visualization-Car-Dealership-Angeles.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/6.Exterior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/7.Interior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/8.Exterior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/9.Exterior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/10.Exterior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/11.Exterior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/12.Exterior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/13.Interior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/14.Exterior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/15.Interior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/16.Interior-3D-Rendering-Visualization-Car-Dealership-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/17.Exterior-3D-Rendering-Visualization-Car-Dealership-LATAM.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/18.Exterior-3D-Rendering-Visualization-Car-Dealership.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/19.Exterior-3D-Rendering-Visualization-Car-Dealership.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/20.Interior-3D-Render-Car-Mock-up-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/21.Interior-3D-Render-Car-Mock-up-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Car-dealership/22.Exterior-3D-Render-Car-Mock-up-High-End-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Metaverse",
    path: "/Metaverse-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/0.Pop-Up-Event-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/1.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/2.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/3.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/4.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/5.Exterior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/6.Exterior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/7.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/8.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/9.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/10.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/11.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Metaverse/12.Interior-Metaverse-3D-Rendering-CGI-Visualization.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Religious",
    path: "/Religious-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Religious/1.Exterior-3D-Rendering-Synagogue-Religious-Bal-Harbour-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Religious/2.Interior-Sanctuary-Bal-Harbour-3D-Rendering-Religious-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Religious/3.Interior-3D-Rendering-Religious-Bal-Harbou-Sanctuary-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Religious/4.Interior-Mikveh-Bal-Harbour-3D-Rendering-Religious-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Religious/5.Interior-Ballroom-Bal-Harbour-3D-Rendering-Religious-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Religious/6.Interior-Beth-Midrash-Bal-Harbour-3D-Rendering-Religious-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Religious/7.Interior-Pre-School-3D-Rendering-Bal-Harbour-Religious-Miami.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Religious/8.Exterior-Synagogue-3D-Rendering-Religious-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Religious/9.Exterior-Synagogue-3D-Rendering-Religious-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Religious/10.Exterior-Synagogue-3D-Rendering-Religious-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Religious/11.Interior-Mikveh-3D-Rendering-Religious-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Religious/12.Interior-Synagogue-3D-Rendering-Religious-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Religious/13.Exterior-Synagogue-3D-Rendering-Religious-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Religious/14.Exterior-Religious-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Religious/15.Interior-Religious-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Religious/16.Exterior-Religious-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Religious/17.Exterior-Religious-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Religious/18.Exterior-Religious-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Religious/19.Exterior-Religious-Church-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Religious/20.Exterior-Religious-Church-3D-Rendering.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Religious/21.Exterior-Religious-Church-Ortodox-3D-Rendering-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Product Design",
    path: "/Product-Design-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/1.Product-Design-3D-Rendering-Bottle-Water.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/2.Product-Design-3D-Rendering-Yellow-Razer.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/3.Product-Design-3D-Rendering-Blue-Razer.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/4.Product-Design-3D-Rendering-Iphone-X.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/5.Product-Design-3D-Rendering-Cell-Phone.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/6.Product-Design-3D-Rendering-Cell-Mockup.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/7.Product-Design-3D-Rendering-Cellphone-Box.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/8.Product-Design-3D-Rendering-Colors-Cellphone-Box.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/9.Product-Design-3D-Rendering-Cell-Mockup-Black.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/10.Product-Design-3D-Rendering-Earphone-Black.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/11.Product-Design-3D-Rendering.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/12.Product-Design-3D-Rendering-Frezzer.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/13.Product-Design-3D-Rendering-Glass-Cup.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/14.Product-Design-3D-Rendering-Coke-Glass.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/15.Product-Design-3D-Rendering-Aquarium.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/16.Product-Design-3D-Rendering-Salvador-Dali.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/17.Product-Design-3D-Rendering-Aquarium-Gold.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/18.Product-Design-3D-Rendering-Aquarium.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/19.Product-Design-3D-Rendering-Orange-Sandals.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/20.Product-Design-3D-Rendering-Glass-Bottle.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 21,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/21.Product-Design-3D-Rendering-Bottle.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 22,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/22.Product-Design-3D-Rendering-Bottle.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 23,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/23.Product-Design-3D-Rendering-Aqua.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 24,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/24.Product-Design-3D-Rendering.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 25,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/25.Product-Design-3D-Rendering-Marimar.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 26,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/26.Product-Design-3D-Rendering-Sandals.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 27,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/27.Product-Design-3D-Rendering-Stand.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 28,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/28.Product-Design-3D-Rendering-Stand.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 29,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/29.Product-Design-3D-Rendering-Purple.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 30,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/30.Product-Design-3D-Rendering-Pencil.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 31,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/31.Product-Design-3D-Rendering-Brush.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 32,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/32.Product-Design-3D-Rendering-Red-Brush.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 33,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/33.Product-Design-3D-Rendering-Liquid-Water.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 34,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/34.Product-Design-3D-Rendering-Water.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 35,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/35.Product-Design-3D-Rendering-Coffee.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 36,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/36.Product-Design-3D-Rendering-extinguisher.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 37,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/37.Product-Design-3D-Rendering-extinguisher-Neon.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 38,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/38.Product-Design-3D-Rendering-Glass-Table.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 39,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/39.Product-Design-3D-Rendering-Pill-Medice.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 40,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/40.Product-Design-3D-Rendering-Ice-Cream.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 41,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/41.Product-Design-3D-Rendering-Ice-Popsicle.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 42,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/42.Product-Design-3D-Rendering-Ligth-Flash.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 43,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/43.Product-Design-3D-Rendering-Furniture-Black.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 44,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/44.Produc-Design-3D-Rendering-Cube-Table.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 45,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/45.Product-Design-3D-Rendering-Furtiture-Orange.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 46,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/46.Product-Design-3D-Rendering-Chair-Outside.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 47,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/47.Product-Design-3D-Rendering-Livingroom.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 48,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/48.Product-Design-3D-Rendering-Livingroom-Couch.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 49,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/49.Product-Design-3D-Rendering-Office-Furniture.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 50,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/50.Product-Design-3D-Rendering-Photo-Studio.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 51,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/51.Product-Design-3D-Rendering-Office-Desk.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 52,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/52.Product-Design-3D-Rendering-Office-Stuff.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 53,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/53.Product-Design-3D-Rendering-Tables-Office.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 54,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/54.Product-Design-3D-Rendering-Coffe-Truck.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 55,
        url: "/assets/img/services/Galeria/Rendering/Product-Design/55.Product-Design-3D-Rendering-Food-Truck.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Axonometric",
    path: "/Axonometric-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/0.Axonometric-3D-Rendering-Visualization-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/1.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/2.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/3.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/4.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/5.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/6.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/7.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/8.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/9.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/10.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/11.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/12.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/13.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/14.Axonometric-3D-Rendering-Visualizatoin-CGI-Bank.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/15.Axonometric-3D-Rendering-Visualizatoin-CGI-Garage.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/16.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/17.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/18.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 20,
        url: "/assets/img/services/Galeria/Rendering/Axonometric/19.Axonometric-3D-Rendering-Visualizatoin-CGI.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
  {
    category: "Hand Drawn",
    path: "/Hand-Drawn-Rendering-Services",
    items: [
      {
        id: 1,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/0.Exterior-Hand-Drawn-Rendering-Colors-Aerial-Palm Islands-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 2,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/1.Exterior-Hand-Drawn-Rendering-Aerial-Palm Islands-Dubai.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 3,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/2.Exterior-Hand-Drawn-Rendering-Real-Estate-Colors-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 4,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/3.Exterior-Hand-Drawn-Rendering-Real-Estate-New-York.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 5,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/4.Exterior-Hand-Drawn-Rendering-Catedral.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 6,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/5.Exterior-Hand-Drawn-Rendering-Catedral-Colors.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 7,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/6.Exterior-Hand-Drawn-Rendering-Dubai-Aerial.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 8,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/7.Exterior-Hand-Drawn-Park-Rendering.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 9,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/8.Exterio-Hand-Drawn-Aerial-Rendering-Building.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 10,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/9.Exterior-Hand-Drawn-Aerial-Rendering-30-St-Mary-Axe.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 11,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/10.Exterior-Hand-Drawn-Aerial-Rendering-Park-Colors.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 12,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/11.Exterior-Hand-Drawn-Aerial-Rendering-Park-Colors.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 13,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/12.Interior-Hand-Drawn-Office-Spaces-Rendering-Colors.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 14,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/13.Interior-Hand-Drawn-Office-Spaces-Rendering.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 15,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/14.Interior-Hand-Drawn-Office-Spaces-Rendering-Colors.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 16,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/15.Interior-Hand-Drawn-Rendering-Mall-Colors.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 17,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/16.Exterior-Hand-Drawn-Rendering-Colors-Aerial.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 18,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/17.Exterior-Hand-Drawn-Central-Park-Rendering-Aerial.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
      {
        id: 19,
        url: "/assets/img/services/Galeria/Rendering/Hand-Drawn/18.Exterior-Hand-Drawn-Farm-Rendering-Aerial.webp",
        name: "",
        location: "",
        usage: "",
        tags: "",
      },
    ],
  },
];
export const galeriesVirtual = [
  {
    id: 1,
    url: "/assets/img/services/Galeria/VirtualR/VR/X-House_VR_Experience-3D_World_renderings_Inc.jpg",
    alt: "X-House VR Experience - 3D World renderings, Inc",
    path: "https://3dworld.nyc/360/VR/XHouse/",
    type: "interactive",
    category: "/Virtual-Reality-First-Class",
  },
  {
    id: 2,
    url: "/assets/img/services/Galeria/VirtualR/VR/Clevia_VR_Experience-3D_World_renderings_Inc.jpg",
    alt: "Clevia VR Experience - 3D World renderings, Inc.",
    path: "https://3dworld.nyc/360/VR/Clevia-Hotel/",
    type: "interactive",
    category: "/Virtual-Reality-First-Class",
  },
  {
    id: 3,
    url: "/assets/img/services/Galeria/VirtualR/VR/Valentina_VR_Experience-3D_World_renderings_Inc.jpg",
    alt: "Valentina VR Experience - 3D World renderings, Inc.",
    path: "https://3dworld.nyc/360/VR/Valentina-Restaurant/",
    type: "interactive",
    category: "/Virtual-Reality-Business",
  },
  {
    id: 4,
    url: "/assets/img/services/Galeria/VirtualR/VR/Brooklyn_Heights_VR_Experience-3D_World_renderings_Inc.jpg",
    alt: "Brooklyn Heights VR Experience - 3D World renderings, Inc.",
    path: "https://3dworld.nyc/360/VR/Exterior-Brooklyn/",
    type: "interactive",
    category: "/Virtual-Reality-Business",
  },
  {
    id: 5,
    url: "/assets/img/services/Galeria/VirtualR/VR/Residential_VR_Experience-3D_World_renderings_Inc.jpg",
    alt: "Residential VR Experience - 3D World renderings, Inc.",
    path: "Residential-Experience",
    url360: "/assets/img/services/Galeria/VirtualR/VR/KITCHEN_FINAL.png",
    type: "imagen",
    category: "/Virtual-Reality-Economy",
  },
  {
    id: 6,
    url: "/assets/img/services/Galeria/VirtualR/VR/Hospitality_VR_Experience-3D_World_renderings_Inc.jpg",
    alt: "Hospitality VR Experience - 3D World renderings, Inc.",
    path: "Hospitality-Experience",
    url360: "/assets/img/services/Galeria/VirtualR/VR/1.render.jpg",
    type: "imagen",
    category: "/Virtual-Reality-Economy",
  },
];
export const galeriesVFX = [
  {
    url: "https://vimeo.com/824909233",
    path: "Dell-Technologies",
    category: "/VFX-Commercial-Services",
  },
  {
    url: "https://vimeo.com/824910203",
    path: "Dell-PSeries",
    category: "/VFX-Commercial-Services",
  },
  {
    url: "https://vimeo.com/824910943",
    path: "Shiner-Beer",
    category: "/VFX-Social-Media-Services",
  },
  {
    url: "https://vimeo.com/825636510",
    path: "Fiat",
    category: "/VFX-Social-Media-Services",
  },
];
export const galeriesAnimation = [
  {
    url: "https://vimeo.com/834916527",
    path: "Architectural-Distrito-Yaax",
    category: "/Architectural-Animation-Services",
  },
  {
    url: "https://vimeo.com/834915647",
    path: "Architectural-Evamar",
    category: "/Architectural-Animation-Services",
  },
  {
    url: "https://vimeo.com/834913781",
    path: "Architectural-Atiko",
    category: "/Architectural-Animation-Services",
  },
  {
    url: "https://vimeo.com/825636841",
    path: "Architectural-Dominons",
    category: "/Architectural-Animation-Services",
  },
  {
    url: "https://vimeo.com/825631769",
    path: "Product-Refrigerador",
    category: "/Product-Design-Animation-Services",
  },
  {
    url: "https://vimeo.com/824905036",
    path: "Product-GrapheneDx",
    category: "/Product-Design-Animation-Services",
  },
  {
    url: "https://vimeo.com/825634373",
    path: "Product-Zaydo",
    category: "/Product-Design-Animation-Services",
  },
  {
    url: "https://vimeo.com/824905814",
    path: "Product-Biocrucible",
    category: "/Product-Design-Animation-Services",
  },
];
